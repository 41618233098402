import { Box, Tab, Tabs } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SiteTopicReportLabelGroupMain from './top-label-groups/SiteTopicReportLabelGroupMain';
import { useUser } from 'contexts/user-context';
import SiteTopicTopTopicsMain from './top-topics/SiteTopicTopTopicsMain';

const PATH_TAB_MAP: Record<string, number> = {
  'top-label-groups': 0,
  'top-topics': 1,
};
const TAB_PATH_LOOKUP: string[] = ['top-label-groups', 'top-topics'];

const SiteTopicItemMain: FC = () => {
  const params = useParams();
  const reportId = params.reportId!;
  const opporId = params.oppId;
  const [tab, setTab] = useState(0);
  const { curIndex } = useUser();

  const navigate = useNavigate();
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const tabPath = TAB_PATH_LOOKUP[newValue];
    if (opporId) {
      navigate(
        `/opportunities/${
          curIndex!.value
        }/${opporId}/reports/${reportId}/${tabPath}`
      );
    } else {
      navigate(`/workbench/${curIndex!.value}/report/${reportId}/${tabPath}`);
    }
  };
  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const path = Boolean(opporId) ? paths[6] : paths[5];
  useEffect(() => {
    if (path && path in PATH_TAB_MAP) {
      setTab(PATH_TAB_MAP[path]);
    } else {
      setTab(0);
    }
  }, [path]);

  return (
    <Box sx={{ ml: 3, mr: 3, mt: 4 }}>
      <Tabs value={tab} onChange={handleChangeTab}>
        <Tab label="Top Label Groups" />
        <Tab label="Top Topics" />
      </Tabs>
      <Box sx={{ mt: 4 }}>{tab === 0 && <SiteTopicReportLabelGroupMain />}</Box>
      <Box sx={{ mt: 4 }}>{tab === 1 && <SiteTopicTopTopicsMain />}</Box>
    </Box>
  );
};

export default SiteTopicItemMain;
